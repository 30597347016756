import React from "react"
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import {Button} from '@common'
import keyartwork from "@images/audiodesires-erotic-audio-keyartwork-v2.svg"


const HeaderWrapper = styled.div`
  height:auto;
  z-index: 20;
  box-sizing:border-box;
  display:flex;
  background-color:#fcd3cc;
  justify-content:center;
  align-items:center;
  @media (max-width:480px) {
    height:500px;
    width:100%;
  } 
  @media (min-width:600px) {
    padding-top:50px;
  } 
  position:relative;
`

const ButtonOnTop = styled(Button)`
  position:relative;
  z-index:20;
`


const ButtonCenterWrapper = styled.div`
  width: 100%;
  display:flex;
  align-items:center;
  justify-content:center;
  @media ${props => props.theme.tabletUp} {
    display:block;
    width:auto;
  }
`

const HeaderContent = styled.div`
  width: 33%;
  z-index: 10;
  @media ${props => props.theme.phoneLand} {
    margin:0px auto 0px auto;
    width: 90%;
    position:absolute;
  }
`

const HeaderGradient = styled.div`
  position: absolute;
  z-index: 10;
  bottom:0px;
  width: 100%;
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), rgb(255, 255, 255));
  height: 150px;
  pointer-events: none;
`

const HeaderTitle = styled.h1`
  margin-top:-100px;
  font-size: 3rem;
  ${props => props.center ? 'text-align:center;' : ''}
  @media ${props => props.theme.phoneLand} {
    font-size: 2rem;
    text-align:center;
    padding-top: 25%;
  }
`

const ImageWrapper = styled.div`
  position:relative;
  padding-right:33%;
  padding-bottom: 33%;
  @media ${props => props.theme.phoneLand} {
    padding-right:100%;
    padding-bottom: 100%;
    position: absolute;
  }

`

const HeroImg = styled.img`
  position:absolute;
  width:100%;
  height:100%;
`

const HeaderText = styled.p`
  font-family:${props => props.theme.mainFontParagraph},'Arial';
  @media ${props => props.theme.phoneLand} {
    ${props => props.showText ? '' : 'display:none;'}
    text-align:center;
  }
`

const HomeHeroSection = ({headerTitle,headerText,ctaText,showText}) => {
  const { t } = useTranslation();

  function redirectToRegister(){
    navigate(t('urls.register'))
  }

  return (
    <>
      <HeaderWrapper> 
        <HeaderContent>
          <HeaderTitle >{headerTitle}</HeaderTitle>
            <HeaderText showText={showText} dangerouslySetInnerHTML={{ __html: headerText}}/>
          <ButtonCenterWrapper>
          <ButtonOnTop shadow inverted onClick={redirectToRegister}>{ctaText}</ButtonOnTop>
          </ButtonCenterWrapper>
        </HeaderContent>
        <HeaderGradient/>
        <ImageWrapper><HeroImg loading="eager" src={keyartwork }/></ImageWrapper>
      </HeaderWrapper> 
    </>
  )
}


export default HomeHeroSection