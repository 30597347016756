import React from "react";
import styled from 'styled-components';
import { Link } from "gatsby";
import { useTranslation } from 'react-i18next';
import { usePageContext } from '@hooks/usePageContext';
import LangSwitcherStrip  from "@multilang/LangSwitcherStrip";
import logo from "@images/logos/logo-black-3.svg";



const Menu = styled.div`
  align-items: center;
  display:flex;
  width:100%;
  background-color: #fcd3cc;
  padding: 50px 10% 25px 10%;
  @media ${props => props.theme.phonePort} {
    padding:20px 5% 25px 5%;
  }
`

const LogoLink = styled(Link)`
  flex-grow:1;
  height: 100%;
  display: flex;
`

const ButtonsWrapper = styled.div`
  font-family:${props => props.theme.mainFontParagraph};
  text-transform:uppercase;
`

const Logo = styled.img`
  width: 280px;
  @media ${props => props.theme.phoneLand} {
    margin:0px 0px;
    width: 100px;
    height:auto;
  }
  @media ${props => props.theme.phonePort} {
    margin:0px 0px;
    width:calc(140px * ${props => props.widthFactor});
    height:auto;
  }
  @media (max-width: 320px) {
    margin:0px 0px;
    width:calc(110px * ${props => props.widthFactor});
    height:auto;
  }
`

const LoginLink= styled(Link)`
  margin: 0px 10px;
  text-decoration:none;
  
  color:${props => props.theme.primaryColor};
  padding:10px 20px;
  white-space: nowrap;
  @media ${props => props.theme.phonePort} {
    padding: 5px 10px;
    font-size:0.8rem;
   
    
  }
`
const RegisterLink= styled(Link)`
  text-decoration:none;
  color:white;
  background-color:${props => props.theme.secondaryColor};
  padding:10px 50px;
  border-radius:25px;
  @media ${props => props.theme.phonePort} {
    padding: 5px 10px;
    font-size:0.8rem;
  }
`


const HeroMenu = ({image, title, text}) => {
  const { t } = useTranslation()
  const { lang } = usePageContext()


  return(
    <>
      <Menu>
        <LogoLink aria-label="Homepage" to={t('urls.root')}>
        {lang === 'en' ?
          <Logo widthFactor={1} src={logo}/> 
        :
          <Logo  widthFactor={0.8} src={logo}/>
        }
        </LogoLink>
        <ButtonsWrapper>
          <LoginLink  to={t('urls.login')} >{t('hero-menu.login')}</LoginLink >
          <RegisterLink  to={t('urls.register')}>{t('hero-menu.cta')}</RegisterLink>
        </ButtonsWrapper>
      </Menu> 
      <LangSwitcherStrip/>
    </>
  )
}

export default HeroMenu 
