import styled from 'styled-components'
import React, {useState} from "react"
import { navigate } from 'gatsby'
import { usePageContext }  from '@hooks/usePageContext'


const Wrapper = styled.div`
  background-color: #F19D9A;
  display:flex;
  align-items: center;
  justify-content: center;
  height:45px;
  @media ${props => props.theme.phonePort} {
    padding: 0px 10px;
    justify-content:space-around;
  }
`

const LangOption = styled.button`
  background-color:${props => props.activeLang ? 'white' : 'transparent'};
  color: ${props => props.activeLang ? '#F19D9A' : 'white'};
  border-radius:13px;
  font-size:12px;
  height:27px;
  padding: 0 20px;
  text-align:center;
 
  font-weight: 500;
  border:1px solid white;
  text-transform:uppercase;
  @media (max-width:320px) {
    font-size:10px;
    padding: 0 15px;
  }
  @media ${props => props.theme.boxLayoutQuery} {
    margin: auto 10px;
  }
`

const LangSwitcherStrip = () => {
  const {paths,lang ,languages} = usePageContext()
  const [showOptions, setShowOptions] = useState(false)
  const FullLangNames = {en: 'English',es: 'Español', de: 'Deutsch'}

  const switchLanguages= (language) => {
    navigate(paths[language])
  }
  
  return(
    <>
      {languages.length > 1 &&
        <Wrapper onClick={()=> setShowOptions(!showOptions)} show={showOptions}>
          {languages.map((language,index) => (
            <LangOption 
              key={index}
              activeLang={lang === language}
              onClick={()=> switchLanguages(language)}>{FullLangNames[language]}</LangOption>
          ))}
        </Wrapper>
      }
    </>
  )
}

export default LangSwitcherStrip